import type { Equipment } from "@prisma/client";
import { Form } from "@remix-run/react";
import * as React from "react";
import { ListBox, UploadImage } from "~/components";

type Props = {
  actionData: {
    errors?: {
      equipment?: string;
      issueDate?: string;
      expirationDate?: string;
      trainingOrgName?: string;
      trainers?: string;
      certUpload?: string;
    };
  };
  equipmentListItems: { id: Equipment["id"]; name: Equipment["name"] }[];
};

export default function UserCertNewForm({
  actionData,
  equipmentListItems,
}: Props) {
  const issueDateRef = React.useRef<HTMLInputElement>(null);
  const expirationDateRef = React.useRef<HTMLInputElement>(null);
  const trainingOrgNameRef = React.useRef<HTMLInputElement>(null);
  const trainerRef = React.useRef<HTMLInputElement>(null);
  const uploadRef = React.useRef<HTMLInputElement>(null);
  const certUploadRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (actionData?.errors?.issueDate) {
      issueDateRef.current?.focus();
    } else if (actionData?.errors?.expirationDate) {
      expirationDateRef.current?.focus();
    } else if (actionData?.errors?.trainingOrgName) {
      trainingOrgNameRef.current?.focus();
    } else if (actionData?.errors?.trainers) {
      trainerRef.current?.focus();
    } else if (actionData?.errors?.certUpload) {
      certUploadRef.current?.focus();
    } else {
      uploadRef.current?.focus();
    }
  }, [actionData]);

  return (
    <Form
      className="space-y-4"
      method="post"
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 8,
        width: "100%",
      }}
      encType="multipart/form-data"
    >
      {/* Issue Date */}
      <div className="sm:col-span-4">
        <label
          htmlFor="issueDate"
          className="block text-sm font-medium text-gray-700"
        >
          Issue Date
        </label>

        <input
          name="issueDate"
          ref={issueDateRef}
          data-cy="issueDate"
          type="date"
          className="picker block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          aria-invalid={actionData?.errors?.issueDate ? true : undefined}
          aria-errormessage={
            actionData?.errors?.issueDate ? "title-error" : undefined
          }
          required
        />

        {actionData?.errors?.issueDate && (
          <div className="pt-1 text-red-700" id="title-error">
            {actionData.errors.issueDate}
          </div>
        )}
      </div>

      {/* Expiration Date */}
      <div className="sm:col-span-4">
        <label
          htmlFor="expirationDate"
          className="block text-sm font-medium text-gray-700"
        >
          Expiration Date
        </label>
        <div className="mt-1">
          <input
            name="expirationDate"
            ref={expirationDateRef}
            data-cy="expirationDate"
            type="date"
            className="picker block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            aria-invalid={actionData?.errors?.expirationDate ? true : undefined}
            aria-errormessage={
              actionData?.errors?.expirationDate ? "title-error" : undefined
            }
            required
          ></input>
        </div>
        {actionData?.errors?.expirationDate && (
          <div className="pt-1 text-red-700" id="title-error">
            {actionData.errors.expirationDate}
          </div>
        )}
      </div>

      {/* Equipment */}
      <div className="sm:col-span-4">
        <label
          htmlFor="issueDate"
          className="block text-sm font-medium text-gray-700"
        >
          Equipment
        </label>
        <ListBox
          id="select-equipment"
          name="equipment"
          items={equipmentListItems}
        />
        {actionData?.errors?.equipment && (
          <div className="pt-1 text-red-700" id="title-error">
            {actionData.errors.equipment}
          </div>
        )}
      </div>

      {/* Training Company */}
      <div className="sm:col-span-4">
        <label
          htmlFor="trainingOrgName"
          className="block text-sm font-medium text-gray-700"
        >
          Training Company
        </label>
        <div className="mt-1">
          <input
            id="trainingOrgName"
            data-cy="trainingOrgName"
            name="trainingOrgName"
            type="text"
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            aria-invalid={
              actionData?.errors?.trainingOrgName ? true : undefined
            }
            aria-errormessage={
              actionData?.errors?.trainingOrgName ? "title-error" : undefined
            }
            required
          />
        </div>
        {actionData?.errors?.trainingOrgName && (
          <div className="pt-1 text-red-700" id="title-error">
            {actionData.errors.trainingOrgName}
          </div>
        )}
      </div>

      {/* Trainers */}
      <div className="sm:col-span-4">
        <label
          htmlFor="trainers"
          className="block text-sm font-medium text-gray-700"
        >
          Trainers
        </label>
        <div className="mt-1">
          <input
            id="trainers"
            name="trainers"
            data-cy="trainers"
            type="text"
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            aria-invalid={actionData?.errors?.trainers ? true : undefined}
            aria-errormessage={
              actionData?.errors?.trainers ? "title-error" : undefined
            }
            required
          />
          <p className="font-small text-sm text-gray-700">
            Separate multiple trainers with a comma
          </p>
        </div>
        {actionData?.errors?.trainers && (
          <div className="pt-1 text-red-700" id="title-error">
            {actionData.errors.trainers}
          </div>
        )}
      </div>

      {/* Upload Certification */}
      <div className="sm:col-span-6">
        <label
          htmlFor="cover-photo"
          className="block text-sm font-medium text-gray-700"
        >
          Upload Certification
        </label>
        <UploadImage error={actionData?.errors?.certUpload} ref={uploadRef} />
      </div>

      {/* Submit */}
      <div className="text-right">
        <button
          type="submit"
          data-cy="submit-cert"
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:border-slate-200 disabled:bg-slate-50 disabled:text-slate-500 disabled:shadow-none sm:w-auto"
        >
          Submit
        </button>
      </div>
    </Form>
  );
}
