import type { OrgLocation } from "@prisma/client";
import { Form } from "@remix-run/react";
import * as React from "react";
import { Input, ListBox } from "~/components";

type Props = {
  actionData: {
    errors: {
      email?: string;
      providedId?: string;
      accessRole?: string;
      locationId?: string;
      title?: string;
    };
  };
  orgLocations?: OrgLocation[];
  onSubmit: () => void;
};

export default function OrgInviteForm({
  actionData,
  orgLocations,
  onSubmit,
}: Props) {
  const emailRef = React.useRef<HTMLInputElement>(null);
  const titleIdRef = React.useRef<HTMLInputElement>(null);
  const providedIdRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (actionData?.errors?.email) {
      emailRef.current?.focus();
    } else if (actionData?.errors?.title) {
      titleIdRef.current?.focus();
    } else if (actionData?.errors?.providedId) {
      providedIdRef.current?.focus();
    }
  }, [actionData]);

  return (
    <div className="overflow-hidden">
      <div className="px-4 py-5 sm:p-6">
        <div className="space-y-8">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Invite To Organization
          </h3>
        </div>

        <Form
          method="post"
          className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-8"
          onSubmit={onSubmit}
        >
          <input type="hidden" name="form" value="inviteOrgUser" />
          <div className="sm:col-span-2">
            <Input
              id="email"
              type="email"
              label="Email"
              ref={emailRef}
              error={actionData?.errors?.email}
              autoFocus={true}
              autocomplete="off"
            />
          </div>

          <div className="sm:col-span-2">
            <Input
              id="title"
              type="text"
              label="Job Title"
              ref={titleIdRef}
              error={actionData?.errors?.title}
              autocomplete="off"
            />
          </div>

          {orgLocations?.length ? (
            <div className="mt-2 sm:col-span-2">
              <label
                htmlFor="location"
                className="block text-sm font-medium text-gray-700"
              >
                Location
              </label>
              <ListBox id="location" name="location" items={orgLocations} />
            </div>
          ) : null}

          <div className="sm:col-span-2">
            <Input
              id="providedId"
              type="text"
              label="Employee ID"
              ref={providedIdRef}
              error={actionData?.errors?.providedId}
              autocomplete="off"
            />
          </div>

          <div className="mt-2 sm:col-span-2">
            <label
              htmlFor="access-role"
              className="block text-sm font-medium text-gray-700"
            >
              Role
            </label>
            <ListBox
              id="access-role"
              name="accessRole"
              items={[
                { id: "MEMBER", name: "Member" },
                { id: "ADMIN", name: "Admin" },
              ]}
              defaultValue={{ id: "MEMBER", name: "Member" }}
            />
          </div>

          <div className="pt-6 sm:col-span-2">
            <button
              type="submit"
              name="invite"
              data-cy="submit-invite-user"
              className="inline-flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Send Email Invite
            </button>
          </div>
        </Form>
      </div>
    </div>
  );
}
