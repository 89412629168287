import { Form } from "@remix-run/react";
import * as React from "react";
import { Input, Notification } from "~/components";
import { NotificationType } from "~/components/ui/notification";

type Props = {
  actionData: any;
};

export default function SettingsPassword({ actionData }: Props) {
  const [showSuccess, setShowSuccess] = React.useState(false);

  const newPasswordRef = React.useRef<HTMLInputElement>(null);
  const currentPasswordRef = React.useRef<HTMLInputElement>(null);
  const confirmPasswordRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (actionData?.errors?.currentPassword) {
      currentPasswordRef.current?.focus();
    } else if (actionData?.errors?.confirmPassword) {
      confirmPasswordRef.current?.focus();
    } else if (actionData?.errors?.newPassword) {
      newPasswordRef.current?.focus();
    } else if (actionData?.success === "updatePassword") {
      setShowSuccess(true);
      // clear fields on success
      if (newPasswordRef.current) {
        newPasswordRef.current.value = "";
      }
      if (currentPasswordRef.current) {
        currentPasswordRef.current.value = "";
      }
      if (confirmPasswordRef.current) {
        confirmPasswordRef.current.value = "";
      }
    }
  }, [actionData]);
  return (
    <div>
      <div className="mt-10 divide-y divide-gray-200">
        <div className="space-y-1">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Password
          </h3>
          <p className="max-w-2xl text-sm text-gray-500">
            Change your password. Must be 8 or more characters.
          </p>
        </div>
        <Form className="mt-6" method="put" replace={true}>
          <input type="hidden" name="_method" value="put" />
          <input type="hidden" name="form" value="updatePassword" />
          <dl className="divide-y divide-gray-200">
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
              <dt className="text-sm font-medium text-gray-500">
                <Input
                  id="newPassword"
                  ref={newPasswordRef}
                  type="password"
                  label="New Password"
                  error={actionData?.errors.newPassword}
                />
                <Input
                  id="confirmPassword"
                  ref={confirmPasswordRef}
                  type="password"
                  label="Confirm Password"
                  error={actionData?.errors?.confirmPassword}
                />
              </dt>
              <dt className="text-sm font-medium text-gray-500">
                <Input
                  id="currentPassword"
                  ref={currentPasswordRef}
                  type="password"
                  label="Current Password"
                  error={actionData?.errors?.currentPassword}
                />
              </dt>
            </div>
            <button
              type="submit"
              className="inline-flex h-10 items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-75 sm:w-auto"
            >
              Change Password
            </button>
          </dl>
        </Form>
      </div>
      <Notification
        isShowing={showSuccess}
        type={NotificationType.SUCCESS}
        hide={setShowSuccess.bind(null, false)}
        title="Password updated!"
        timeout={5000}
      />
    </div>
  );
}
