import * as React from "react";

export function joinChildren(children: JSX.Element[], separator: JSX.Element) {
  return children.reduce((result: JSX.Element[], child: JSX.Element, index) => {
    if (index < children.length - 1) {
      return result.concat([child, separator]);
    }

    return result.concat(child);
  }, []);
}

export default function UpdateFormItem({
  label,
  value,
  name,
  type,
  input,
  isEditing,
  setIsEditing,
  additionalActions,
}: {
  label: string;
  value: string | React.ReactNode;
  name: string;
  type: "EDIT" | "VIEW";
  input?: React.ReactNode;
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
  additionalActions?: JSX.Element[]; // options next to "Update"
}) {
  let actions = [
    <button
      key="update"
      onClick={setIsEditing.bind(null, true)}
      type="button"
      className="rounded-md bg-white font-medium text-indigo-600  hover:text-indigo-500 focus:outline-none"
    >
      Update
    </button>,
  ];
  if (additionalActions) {
    actions = actions.concat(additionalActions);
  }
  return (
    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
      <dt className="text-sm font-medium text-gray-500">{label}</dt>
      <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
        {isEditing && type === "EDIT" ? (
          <>
            <div>{input}</div>
            <span className="flex-grow" />
          </>
        ) : (
          <span className="flex-grow" data-cy={name}>
            {value}
          </span>
        )}

        {type === "EDIT" ? (
          isEditing ? (
            <>
              <button
                className="mr-4 inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:border-slate-200 disabled:bg-slate-50 disabled:text-slate-500 disabled:shadow-none sm:w-auto"
                type="submit"
              >
                Save
              </button>
              <button
                onClick={setIsEditing.bind(null, false)}
                type="button"
                className="rounded-md bg-white font-medium text-gray-900 focus:outline-none"
              >
                Cancel
              </button>
            </>
          ) : (
            <span className="ml-4 flex-shrink-0">
              {joinChildren(
                actions,
                <span
                  key="separator"
                  className="ml-3 mr-3 text-gray-300"
                  aria-hidden="true"
                >
                  {" "}
                  |{" "}
                </span>
              )}
            </span>
          )
        ) : null}
      </dd>
    </div>
  );
}
