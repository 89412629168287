import {
  Cog6ToothIcon,
  EnvelopeIcon,
  IdentificationIcon,
  MapPinIcon,
  PencilIcon,
  PhoneIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import type {
  Org,
  OrgLocation,
  OrgUser,
  User,
  UserProfile,
} from "@prisma/client";
import { NavLink } from "@remix-run/react";

type Props = {
  user: Pick<User, "email"> & { profile: UserProfile };
  orgUser?: OrgUser & { location: OrgLocation; org: Org };
  showContact?: boolean;
  canEdit?: boolean;
};

export default function UserProfileHeader({
  user,
  orgUser,
  showContact,
  canEdit,
}: Props) {
  return (
    <>
      <div className="border-b border-gray-200 bg-white px-2 pb-4 pr-4">
        <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
          <div className="ml-4 mt-4">
            <div className="flex items-center">
              {/* <div className="flex-shrink-0">
              <img
                className="h-12 w-12 rounded-full"
                src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                alt=""
              />
            </div> */}
              <div className="ml-4">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  {user.profile.firstName} {user.profile.lastName}
                </h3>
                {orgUser ? (
                  <div className="mt-2 sm:flex sm:justify-between">
                    <div className="sm:flex">
                      {orgUser?.title ? (
                        <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:mr-6">
                          <UsersIcon
                            className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                          {orgUser.title}
                        </p>
                      ) : null}
                      {orgUser?.providedId ? (
                        <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:mr-6">
                          <IdentificationIcon
                            className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                          {orgUser.providedId}
                        </p>
                      ) : null}
                      {orgUser?.location ? (
                        <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:mr-6">
                          <MapPinIcon
                            className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                          {orgUser.location?.name}
                        </p>
                      ) : null}
                      {orgUser.accessRole === "ADMIN" ? (
                        <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:mr-6">
                          <Cog6ToothIcon
                            className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                          Admin
                        </p>
                      ) : null}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          {canEdit ? (
            <NavLink to="edit" className="ml-4 mt-4 flex flex-shrink-0">
              <button className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                <PencilIcon
                  className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <span>Edit User</span>
              </button>
            </NavLink>
          ) : null}
        </div>
      </div>
      {showContact ? (
        <div className="border-b border-gray-200 bg-white px-2 pt-4 pb-4 pr-4">
          <div className="ml-2 flex flex-wrap items-center  sm:flex-nowrap">
            {user.profile.phone ? (
              <a
                href={`tel:${user.profile.phone}`}
                type="button"
                className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                <PhoneIcon
                  className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <span>Phone</span>
              </a>
            ) : null}

            <a
              href={`mailto:${user.email}`}
              type="button"
              className="relative ml-6 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              <EnvelopeIcon
                className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <span>Email</span>
            </a>
          </div>
        </div>
      ) : null}
    </>
  );
}
