import { CalendarIcon } from "@heroicons/react/24/outline";
import type { Cert, Equipment, TrainingOrg } from "@prisma/client";
import { NavLink } from "@remix-run/react";
import { formatExpirationDate } from "~/utils/formatStrings";

type Props = {
  certs: Array<
    Cert & { trainingOrg: TrainingOrg; equipment: Equipment | null }
  >;
  certNavPath: string;
};

export default function UserCerts({ certs, certNavPath }: Props) {
  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-md">
      <ul className="divide-y divide-gray-200">
        {certs.map((cert) => (
          <li key={cert.id}>
            <NavLink
              to={`${certNavPath}${cert.id}`}
              key={cert.id}
              prefetch="intent"
              className="block hover:bg-gray-50"
            >
              <div className="px-4 py-4 sm:px-6">
                <div className="flex items-center justify-between">
                  {cert.equipment ? (
                    <p className="truncate text-sm font-medium text-indigo-600">
                      {cert.equipment?.name}
                    </p>
                  ) : null}
                  <div className="ml-2 flex flex-shrink-0"></div>
                </div>
                <div className="mt-2 sm:flex sm:justify-between">
                  <div className="sm:flex">
                    <p className="flex items-center text-sm text-gray-500">
                      <CalendarIcon
                        className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="block">
                        <time dateTime={cert.expirationDate.toString()}>
                          {formatExpirationDate(new Date(cert.expirationDate))}
                        </time>
                      </span>
                    </p>
                    <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                      {/* <MapPinIcon
                            className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                          Location */}
                    </p>
                  </div>
                  <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                    {/* <CalendarIcon
                          className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                          aria-hidden="true"
                        /> */}
                    <p>
                      {/* <time dateTime={cert.expirationDate.toString()}>
                            {formatExpirationDate(
                              new Date(cert.expirationDate)
                            )}
                          </time> */}
                    </p>
                  </div>
                </div>
              </div>
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
}
