import { classNames } from "~/utils/utils";

type Stat = {
  name: string;
  stat: string;
  icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  iconColor?: string;
  iconTextColor?: string;
  onView: Function;
  onClear: Function;
  active: boolean;
};

type Props = {
  stats: Stat[];
};

export default function UserStats({ stats }: Props) {
  return (
    <div>
      <dl className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        {stats.map((item) => (
          <div
            key={item.name}
            className={`relative overflow-hidden rounded-lg bg-white px-4 pt-4 pb-10 shadow sm:px-4 sm:pt-4 ${
              item.active ? "border-2 border-indigo-500 bg-indigo-100" : ""
            }}`}
          >
            <dt>
              {item.icon ? (
                <div
                  className={classNames(
                    "absolute rounded-md p-3",
                    item.iconColor || "bg-indigo-500"
                  )}
                >
                  <item.icon
                    className={classNames(
                      "h-6 w-6",
                      item.iconTextColor || "text-white"
                    )}
                    aria-hidden="true"
                  />
                </div>
              ) : null}
              <p
                className={
                  "truncate text-sm font-medium text-gray-500" + item.icon
                    ? " ml-16"
                    : ""
                }
              >
                {item.name}
              </p>
            </dt>
            <dd
              className={
                "flex items-baseline pb-6 sm:pb-7" + item.icon ? " ml-16" : ""
              }
            >
              <p className="text-2xl font-semibold text-gray-900">
                {item.stat}
              </p>
              {/* <p
                className={classNames(
                  item.changeType === "increase"
                    ? "text-green-600"
                    : "text-red-600",
                  "ml-2 flex items-baseline text-sm font-semibold"
                )}
              >
                {item.changeType === "increase" ? (
                  <ArrowUpIcon
                    className="h-5 w-5 flex-shrink-0 self-center text-green-500"
                    aria-hidden="true"
                  />
                ) : (
                  <ArrowDownIcon
                    className="h-5 w-5 flex-shrink-0 self-center text-red-500"
                    aria-hidden="true"
                  />
                )}

                <span className="sr-only">
                  {" "}
                  {item.changeType === "increase"
                    ? "Increased"
                    : "Decreased"}{" "}
                  by{" "}
                </span>
                {item.change}
              </p> */}
              <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-2 sm:px-6">
                <div className="text-sm">
                  {item.active ? (
                    <button
                      onClick={() => item.onClear()}
                      className="font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      Clear filter
                    </button>
                  ) : (
                    <button
                      onClick={() => item.onView()}
                      className="font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      View all
                      <span className="sr-only"> {item.name} stats</span>
                    </button>
                  )}
                </div>
              </div>
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
}
