export default function BrandedBanner() {
  return (
    <div className="w-full max-w-md space-y-8">
      <div>
        <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
          CertStack
        </h2>
      </div>
    </div>
  );
}
