import type { Cert, CertUpload, Equipment, TrainingOrg } from "@prisma/client";
import { Form } from "@remix-run/react";
import { format } from "date-fns";
import * as React from "react";
import {
  CertUploadFormItem,
  ListBox,
  Notification,
  UpdateFormItem,
} from "~/components";
import { NotificationType } from "~/components/ui/notification";
import { formatExpirationDate } from "~/utils/formatStrings";
import { dateWithTz } from "~/utils/utils";

type Props = {
  type: "EDIT" | "VIEW";
  cert: Cert & {
    trainingOrg: TrainingOrg;
    equipment: Equipment;
    certUploads: CertUpload[];
  };
  equipmentListItems: { id: string; name: string }[];
  actionData: {
    errors?: {
      equipment?: string;
      issueDate?: string;
      expirationDate?: string;
      trainingOrgName?: string;
      trainers?: string;
      certUploads?: string;
    };
    success?: string;
  };
};

const defaultNotify = {
  isShowing: false,
  type: NotificationType.SUCCESS,
  title: "",
  message: undefined,
  timeout: undefined,
};

export default function UserCertForm({
  cert,
  type,
  equipmentListItems,
  actionData,
}: Props) {
  const defaultState = {
    equipment: false,
    issueDate: false,
    expirationDate: false,
    trainingOrg: false,
    trainers: false,
    certUploads: false,
  };
  const [isEditing, setIsEditing] = React.useState(defaultState);
  const [notify, setNotify] = React.useState<{
    isShowing: boolean;
    type: NotificationType;
    title: string;
    message?: string;
    timeout?: number;
  }>(defaultNotify);

  function onSubmit(title: string) {
    setIsEditing(defaultState);
    setNotify({
      isShowing: true,
      type: NotificationType.SUCCESS,
      title,
    });
  }

  const issueDateRef = React.useRef<HTMLInputElement>(null);
  const expirationDateRef = React.useRef<HTMLInputElement>(null);
  const trainingOrgNameRef = React.useRef<HTMLInputElement>(null);
  const trainersRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (actionData?.errors?.issueDate) {
      issueDateRef.current?.focus();
    } else if (actionData?.errors?.expirationDate) {
      expirationDateRef.current?.focus();
    } else if (actionData?.errors?.trainingOrgName) {
      trainingOrgNameRef.current?.focus();
    } else if (actionData?.errors?.trainers) {
      trainersRef.current?.focus();
    } else if (actionData?.success) {
      setTimeout(() => {
        setNotify(defaultNotify);
      }, 2000);
    }
  }, [actionData]);

  function onToggleEditing(field: string, value: boolean) {
    if (value) {
      setIsEditing((prev) => ({ ...defaultState, [field]: true }));
    } else {
      setIsEditing(defaultState);
    }
  }

  return (
    <>
      <Form
        className="space-y-4"
        method="patch"
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 8,
          width: "100%",
        }}
        encType="multipart/form-data"
        onSubmit={onSubmit.bind(null, "Certification updated!")}
        replace={true}
      >
        <dl className="divide-y divide-gray-200">
          <UpdateFormItem
            label="Issue Date"
            value={format(dateWithTz(cert.issueDate.toString()), "MMM d, yyyy")}
            name="issueDate"
            type={type}
            setIsEditing={onToggleEditing.bind(null, "issueDate")}
            isEditing={isEditing.issueDate}
            input={
              <div className="flex flex-row justify-center">
                <div>
                  <input
                    name="issueDate"
                    ref={issueDateRef}
                    data-cy="issueDate"
                    type="date"
                    className="picker block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    aria-invalid={
                      actionData?.errors?.issueDate ? true : undefined
                    }
                    aria-errormessage={
                      actionData?.errors?.issueDate ? "title-error" : undefined
                    }
                    defaultValue={format(
                      dateWithTz(cert.issueDate.toString()),
                      "yyyy-MM-dd"
                    )}
                  />
                  {actionData?.errors?.issueDate && (
                    <div className="pt-1 text-red-700" id="title-error">
                      {actionData?.errors?.issueDate}
                    </div>
                  )}
                </div>
                {/* <UpdateButton field="issueDate" /> */}
              </div>
            }
          />

          <UpdateFormItem
            label="Expiration Date"
            value={
              <>
                <span>
                  {format(
                    dateWithTz(cert.expirationDate.toString()),
                    "MMM d, yyyy"
                  )}
                </span>
                <span className="block text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {formatExpirationDate(new Date(cert.expirationDate))}
                </span>
              </>
            }
            name="expirationDate"
            type={type}
            setIsEditing={onToggleEditing.bind(null, "expirationDate")}
            isEditing={isEditing.expirationDate}
            input={
              <div className="mt-1 flex flex-row justify-center">
                <div>
                  <input
                    name="expirationDate"
                    ref={expirationDateRef}
                    data-cy="expirationDate"
                    type="date"
                    className="picker block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    aria-invalid={
                      actionData?.errors?.expirationDate ? true : undefined
                    }
                    aria-errormessage={
                      actionData?.errors?.expirationDate
                        ? "title-error"
                        : undefined
                    }
                    defaultValue={format(
                      dateWithTz(cert.expirationDate.toString()),
                      "yyyy-MM-dd"
                    )}
                  />
                  {actionData?.errors?.expirationDate && (
                    <div className="pt-1 text-red-700" id="title-error">
                      {actionData?.errors?.expirationDate}
                    </div>
                  )}
                </div>
              </div>
            }
          />

          <UpdateFormItem
            label="Equipment"
            value={cert.equipment?.name}
            name="equipment"
            type={type}
            setIsEditing={onToggleEditing.bind(null, "equipment")}
            isEditing={isEditing.equipment}
            input={
              <div className="flex flex-row justify-center">
                <ListBox
                  id="select-equipment"
                  name="equipment"
                  items={equipmentListItems}
                  defaultValue={equipmentListItems.find(
                    (item) => item.id === cert.equipment?.id
                  )}
                />
              </div>
            }
          />

          <UpdateFormItem
            label="Training Company"
            value={cert.trainingOrg?.name}
            name="trainingOrg"
            type={type}
            setIsEditing={onToggleEditing.bind(null, "trainingOrg")}
            isEditing={isEditing.trainingOrg}
            input={
              <div className="mt-1 flex flex-row justify-center">
                <div>
                  <input
                    id="trainingOrgName"
                    data-cy="trainingOrgName"
                    ref={trainingOrgNameRef}
                    name="trainingOrgName"
                    type="text"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    aria-invalid={
                      actionData?.errors?.trainingOrgName ? true : undefined
                    }
                    aria-errormessage={
                      actionData?.errors?.trainingOrgName
                        ? "title-error"
                        : undefined
                    }
                    defaultValue={cert.trainingOrg?.name}
                  />

                  {actionData?.errors?.trainingOrgName && (
                    <div className="pt-1 text-red-700" id="title-error">
                      {actionData?.errors?.trainingOrgName}
                    </div>
                  )}
                </div>
              </div>
            }
          />
          <UpdateFormItem
            label="Trainer(s)"
            value={cert.trainers}
            name="trainers"
            type={type}
            setIsEditing={onToggleEditing.bind(null, "trainers")}
            isEditing={isEditing.trainers}
            input={
              <div className="mt-1 flex flex-row justify-center">
                <div>
                  <input
                    id="trainers"
                    name="trainers"
                    data-cy="trainers"
                    ref={trainersRef}
                    type="text"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    aria-invalid={
                      actionData?.errors?.trainers ? true : undefined
                    }
                    aria-errormessage={
                      actionData?.errors?.trainers ? "title-error" : undefined
                    }
                    defaultValue={cert.trainers}
                  />
                  <p className="font-small text-sm text-gray-700">
                    Separate multiple trainers with a comma
                  </p>
                  {actionData?.errors?.trainers && (
                    <div className="pt-1 text-red-700" id="title-error">
                      {actionData?.errors?.trainers}
                    </div>
                  )}
                </div>
              </div>
            }
          />

          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
            <dt className="text-sm font-medium text-gray-500">Uploads</dt>
            <dd className="relative mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <div className="absolute right-0">
                <span className="ml-4 flex-shrink-0">
                  {isEditing.certUploads ? (
                    <button
                      onClick={setIsEditing.bind(null, defaultState)}
                      type="button"
                      className="rounded-md bg-white font-medium text-gray-700 hover:text-black focus:outline-none focus:ring-2 focus:ring-offset-2"
                    >
                      Cancel
                    </button>
                  ) : (
                    <button
                      onClick={onToggleEditing.bind(null, "certUploads", true)}
                      type="button"
                      className="rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      Update
                    </button>
                  )}
                </span>
              </div>

              <div className="mt-6">
                <CertUploadFormItem
                  cert={cert}
                  isEditing={isEditing.certUploads}
                />
              </div>
            </dd>
            <dd className="relative text-sm text-gray-900 sm:col-span-2 sm:mt-0"></dd>
          </div>
        </dl>
      </Form>
      <Notification
        isShowing={notify.isShowing}
        type={notify.type}
        hide={setNotify.bind(null, defaultNotify)}
        title={notify.title}
        message={notify.message}
        timeout={5000}
      />
    </>
  );
}
