import { Switch } from "@headlessui/react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import type { Org, OrgLocation, OrgUser } from "@prisma/client";
import { classNames } from "~/utils/utils";

type Props = {
  userOrg: OrgUser & { org: Org; location: OrgLocation };
};

export default function SettingsNotifications({ userOrg }: Props) {
  const isOrgAdmin = userOrg?.accessRole === "ADMIN";
  return (
    <div>
      <div
        className="my-4 flex items-center rounded-lg bg-yellow-100 p-4 text-sm text-yellow-700 dark:bg-yellow-200 dark:text-yellow-800"
        role="alert"
      >
        <InformationCircleIcon className="mr-2 h-6 w-6" />
        <span className="sr-only">Info</span>
        <div>
          <span className="font-medium">Coming soon!</span> Notifications will
          be released at a later date.
        </div>
      </div>
      <div className="mt-10 divide-y divide-gray-200">
        <div className="space-y-1">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Personal Notifications
          </h3>
          <p className="max-w-2xl text-sm text-gray-500">
            Manage notifications for you certifications.
          </p>
        </div>
        <div className="mt-6">
          <dl className="divide-y divide-gray-200">
            <Switch.Group
              as="div"
              className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5"
            >
              <Switch.Label
                as="dt"
                className="text-sm font-medium text-gray-500"
                passive
              >
                Disable All Personal Notifications
              </Switch.Label>
              <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <Switch
                  checked={false}
                  className={classNames(
                    true ? "bg-purple-600" : "bg-gray-200",
                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 sm:ml-auto"
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      true ? "translate-x-5" : "translate-x-0",
                      "inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>
              </dd>
            </Switch.Group>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
              <dt className="text-sm font-medium text-gray-500">
                Expiration Notifications
              </dt>
              <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <div className="flex flex-grow">
                  <div className="mr-2">60 days</div>
                  <div className="ml-2 mr-2">30 days</div>
                  <div className="ml-2">1 day</div>
                </div>

                <span className="ml-4 flex flex-shrink-0 items-start space-x-4">
                  <button
                    type="button"
                    className="rounded-md bg-white font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                  >
                    Update
                  </button>
                </span>
              </dd>
            </div>
          </dl>
        </div>
      </div>
      {isOrgAdmin && (
        <div className="mt-10 divide-y divide-gray-200">
          <div className="space-y-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Admin Notifications
            </h3>
            <p className="max-w-2xl text-sm text-gray-500">
              Manage notifications for your users.
            </p>
          </div>
          <div className="mt-6">
            <dl className="divide-y divide-gray-200">
              <Switch.Group
                as="div"
                className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5"
              >
                <Switch.Label
                  as="dt"
                  className="text-sm font-medium text-gray-500"
                  passive
                >
                  Disable All Admin Notifications
                </Switch.Label>
                <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  <Switch
                    checked={false}
                    className={classNames(
                      true ? "bg-purple-600" : "bg-gray-200",
                      "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 sm:ml-auto"
                    )}
                  >
                    <span
                      aria-hidden="true"
                      className={classNames(
                        true ? "translate-x-5" : "translate-x-0",
                        "inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                      )}
                    />
                  </Switch>
                </dd>
              </Switch.Group>
              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
                <dt className="text-sm font-medium text-gray-500">
                  Expiry Notification Period
                </dt>
                <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  <div className="flex flex-grow">
                    <div className="mr-2">30 days</div>
                    <div className="ml-2">Expired</div>
                  </div>
                  <span className="ml-4 flex flex-shrink-0 items-start space-x-4">
                    <button
                      type="button"
                      className="rounded-md bg-white font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                    >
                      Update
                    </button>
                  </span>
                </dd>
              </div>
              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
                <dt className="text-sm font-medium text-gray-500">Group By</dt>
                <dd className="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  <span className="flex-grow">Location</span>
                  <span className="ml-4 flex flex-shrink-0 items-start space-x-4">
                    <button
                      type="button"
                      className="rounded-md bg-white font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                    >
                      Update
                    </button>
                  </span>
                </dd>
              </div>
            </dl>
          </div>
        </div>
      )}
    </div>
  );
}
