import {
  CalendarIcon,
  IdentificationIcon,
  MapPinIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import type {
  Cert,
  Equipment,
  OrgLocation,
  User,
  UserProfile,
} from "@prisma/client";
import type { CertStatus } from "~/models/orgUserCerts.server";
import { classNames } from "~/utils/utils";

type Props = {
  user: User & {
    certs: Array<Cert & { equipment: Equipment; status: CertStatus }>;
    profile: UserProfile;
    title: string | null;
    location?: OrgLocation;
    warnings: string[];
    providedId: string | null;
  };
};

function colorByStatus(status: CertStatus) {
  switch (status) {
    case "expired":
      return "bg-red-100 text-red-800";
    case "in30Days":
    case "in60Days":
      return "bg-yellow-100 text-yellow-800";
    case "active":
      return "bg-green-100 text-green-800";
    default:
      return "bg-gray-100 text-gray-800";
  }
}

function EquipmentTag({ name, status }: { name: string; status: CertStatus }) {
  return (
    <span
      className={classNames(
        "inline-flex rounded-full px-2 text-xs font-semibold leading-5",
        colorByStatus(status)
      )}
    >
      {name}
    </span>
  );
}

export default function AdminUserItem({ user }: Props) {
  return (
    <div className="px-4 py-4 sm:px-6">
      <div className="flex items-center justify-between">
        <p className="truncate text-sm font-medium text-indigo-600">
          {user.profile.firstName} {user.profile.lastName}
        </p>
        <div>
          <ul className="ml-2 flex flex-shrink-0">
            {user.certs.map((cert) => (
              <li key={cert.id}>
                <EquipmentTag
                  name={cert.equipment?.name}
                  status={cert.status}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="mt-2 sm:flex sm:justify-between">
        <div className="sm:flex">
          {user.title ? (
            <p className="flex items-center text-sm text-gray-500 sm:mr-6">
              <UsersIcon
                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              {user.title}
            </p>
          ) : null}
          {user.providedId ? (
            <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:mr-6">
              <IdentificationIcon
                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              {user.providedId}
            </p>
          ) : null}
          {user.location ? (
            <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:mr-6">
              <MapPinIcon
                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              {user.location?.name}
            </p>
          ) : null}
        </div>
        {user.warnings.length ? (
          <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
            <CalendarIcon
              className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
              aria-hidden="true"
            />
            <p>
              {user.warnings[0]}
              {/* <time dateTime={position.closeDate}>
                {user.position.closeDateFull}
              </time> */}
            </p>
          </div>
        ) : null}
      </div>
    </div>
  );
}
