import { forwardRef } from "react";

type Props = {
  id: string;
  label?: string;
  type:
    | "text"
    | "password"
    | "email"
    | "number"
    | "tel"
    | "url"
    | "search"
    | "date"
    | "time"
    | "datetime-local"
    | "month"
    | "week"
    | "color";
  error?: string; // error message
  autocomplete?: string; // autocomplete attribute
  required?: boolean;
  autoFocus?: boolean;
  defaultValue?: any;
  className?: string;
  inputClassName?: string;
  disabled?: boolean;
};

const Input = forwardRef(function Input(
  {
    id,
    label,
    type,
    error,
    autocomplete,
    required,
    autoFocus,
    defaultValue,
    className,
    inputClassName,
    disabled,
  }: Props,
  ref: any
) {
  return (
    <div className={"mt-4 " + (className || "")}>
      {label ? (
        <label htmlFor={id} className="block text-sm font-medium text-gray-700">
          {label}
        </label>
      ) : null}
      <div className="mt-1">
        <input
          ref={ref}
          data-cy={id}
          id={id}
          autoFocus={autoFocus}
          required={required}
          name={id}
          type={type}
          autoComplete={autocomplete}
          aria-invalid={error ? true : undefined}
          aria-describedby={`${id}-error`}
          className={
            "block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm" +
              " " +
              inputClassName || ""
          }
          defaultValue={defaultValue}
          disabled={disabled}
        />
        {error && (
          <div className="pt-1 text-red-700" id={`${id}-error`}>
            {error}
          </div>
        )}
      </div>
    </div>
  );
});

export default Input;
