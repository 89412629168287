import {
  ClipboardDocumentListIcon,
  EnvelopeIcon,
  IdentificationIcon,
  MapPinIcon,
  PlusIcon,
  TrashIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import type {
  Org,
  OrgLocation,
  OrgUser,
  OrgUserInvite,
  OrgUserRequest,
} from "@prisma/client";
import { Form, NavLink } from "@remix-run/react";
import * as React from "react";
import {
  ListBox,
  Modal,
  Notification,
  OrgInviteForm,
  OrgRequestAcceptForm,
} from "~/components";
import { NotificationType } from "~/components/ui/notification";
import { DOMAIN } from "~/env.server";
import type { User, UserProfile } from "~/models/user.server";

type Props = {
  actionData: any;
  user: Pick<User, "id"> & Pick<User, "email"> & { profile: UserProfile };
  orgUsers: Array<
    OrgUser & { location?: OrgLocation; user: User & { profile: UserProfile } }
  >;
  orgInvites: Array<OrgUserInvite & { location?: OrgLocation }>;
  orgUserRequests: Array<
    OrgUserRequest & { user: User & { profile: UserProfile } }
  >;
  orgLocations?: OrgLocation[];
  org: Pick<Org, "id" | "name" | "slug">;
  submit: any;
};

const accessRoles = [
  { id: "MEMBER", name: "Member" },
  { id: "ADMIN", name: "Admin" },
];

const defaultNotify = {
  isShowing: false,
  type: NotificationType.SUCCESS,
  title: "",
  message: undefined,
  timeout: undefined,
};

export default function SettingsUsers({
  actionData,
  user,
  org,
  orgUsers,
  orgInvites,
  orgUserRequests,
  orgLocations,
  submit,
}: Props) {
  const [inviteModalOpen, setInviteModalOpen] = React.useState(false);
  const [orgAcceptModalOpen, setOrgAcceptModalOpen] = React.useState<{
    open: boolean;
    userId?: string;
    email?: string;
  }>({ open: false });
  const [notify, setNotify] = React.useState<{
    isShowing: boolean;
    type: NotificationType;
    title: string;
    message?: string;
    timeout?: number;
  }>(defaultNotify);

  function handleAccessRoleChange(userId: string, accessRole: string) {
    submit(
      { form: "updateOtherOrgUser", userId, accessRole },
      {
        method: "patch",
        replace: true,
      }
    );
  }
  // server rendering users DOMAIN, client rendering uses window.location.origin
  const portalLink = `${DOMAIN || window.location.origin}/join/${org.slug}`;
  async function copyToClipboard() {
    try {
      await navigator.clipboard.writeText(portalLink);
      setNotify({
        isShowing: true,
        type: NotificationType.SUCCESS,
        title: "Link copied to clipboard",
        timeout: 2000,
      });
    } catch (error) {}
  }

  function onSubmitInvite() {
    setNotify({
      isShowing: true,
      type: NotificationType.SUCCESS,
      title: "Invite Sent",
      message: "The invite has been sent.",
      timeout: 5000,
    });
    setInviteModalOpen(false);
  }

  function onAcceptRequest(user: { id: string; email: string }) {
    setNotify({
      isShowing: true,
      type: NotificationType.SUCCESS,
      title: "Request Accepted",
      timeout: 5000,
    });
    setOrgAcceptModalOpen({ open: true, userId: user.id, email: user.email });
  }

  function onRejectRequest() {
    setNotify({
      isShowing: true,
      type: NotificationType.SUCCESS,
      title: "Request Rejected",
      timeout: 5000,
    });
  }

  function onUserRemoved() {
    setNotify({
      isShowing: true,
      type: NotificationType.SUCCESS,
      title: "User Removed",
      timeout: 5000,
    });
  }

  function onSubmitOtherOrgUserProfileUpdate() {
    setNotify({
      isShowing: true,
      type: NotificationType.SUCCESS,
      title: "User Profile Updated",
      timeout: 5000,
    });
    setOrgAcceptModalOpen({ open: false });
  }

  return (
    <div>
      <div className="mt-10 divide-y divide-gray-200">
        <div className="space-y-1">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Invite Users
          </h3>
          <div className="max-w-l flex flex-col">
            <div>
              <p className="py-4 text-sm text-gray-500">
                Invite individuals to join your organization.
              </p>
              <button
                onClick={() => setInviteModalOpen(true)}
                type="button"
                data-cy="nav-invite-user"
                className="inline-flex h-10 items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
              >
                <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                Invite User By Email
              </button>
            </div>
            <div className="mt-4">
              <span>OR</span>
            </div>
            <div>
              <p className="py-4 text-sm text-gray-500">
                Share the portal link. Users request to join.
                <span className="block">
                  Admins can approve or reject join requests.
                </span>
              </p>

              <button
                type="button"
                data-tooltip-target="tooltip-bottom"
                data-tooltip-placement="bottom"
                onClick={copyToClipboard}
                className="flex text-sm font-medium text-indigo-500 hover:text-indigo-600"
              >
                {portalLink}
                <ClipboardDocumentListIcon className="ml-2 h-5 w-5" />
              </button>
            </div>
          </div>
        </div>
      </div>
      {orgUserRequests.length ? (
        <div className="mt-10 divide-y divide-gray-200">
          <div className="space-y-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Requests to Join
            </h3>
            <p className="max-w-2xl text-sm text-gray-500">
              Approve or reject requests to join your organization.
            </p>
          </div>
          <div className="mt-6">
            <div className="overflow-hidden bg-white shadow sm:rounded-md">
              <ul className="divide-y divide-gray-200">
                {orgUserRequests.map(({ user }) => (
                  <li key={user.id}>
                    <div className="block hover:bg-gray-50">
                      <div className="px-4 py-4 sm:px-6">
                        <div className="flex items-center justify-between">
                          <p className="truncate text-sm font-medium text-indigo-600">
                            {`${user.profile.firstName || ""} ${
                              user.profile.lastName || ""
                            }`}
                            <span className="ml-2 inline-flex rounded-full bg-indigo-400 px-2 text-xs font-semibold leading-5 text-white">
                              Awaiting Approval
                            </span>
                          </p>
                          <div className="ml-2 flex w-40 flex-shrink-0">
                            <div className="ml-4 flex justify-center align-middle">
                              <Form
                                method="delete"
                                onSubmit={onAcceptRequest.bind(null, {
                                  id: user.id,
                                  email: user.email,
                                })}
                                replace={true}
                                className="align-center flex flex-col justify-center"
                              >
                                <input
                                  type="hidden"
                                  name="_method"
                                  value="delete"
                                />
                                <input
                                  type="hidden"
                                  name="form"
                                  value="acceptOrgRequest"
                                />
                                <button
                                  type="submit"
                                  name="acceptOrgRequestUserId"
                                  value={user.id}
                                  className="rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none"
                                >
                                  Accept
                                </button>
                              </Form>
                              <span
                                key="separator"
                                className="ml-3 mr-3 text-gray-300"
                                aria-hidden="true"
                              >
                                {" "}
                                |{" "}
                              </span>
                              <Form
                                method="delete"
                                replace={true}
                                onSubmit={onRejectRequest}
                                className="align-center flex flex-col justify-center"
                              >
                                <input
                                  type="hidden"
                                  name="_method"
                                  value="delete"
                                />
                                <input
                                  type="hidden"
                                  name="form"
                                  value="rejectOrgRequest"
                                />
                                <button
                                  type="submit"
                                  name="rejectOrgRequestUserId"
                                  value={user.id}
                                  className="rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none"
                                >
                                  Reject
                                </button>
                              </Form>
                            </div>
                          </div>
                        </div>
                        <div className="mt-2 sm:flex sm:justify-between">
                          <div className="sm:flex">
                            <p className="flex items-center text-sm text-gray-500">
                              <EnvelopeIcon
                                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                aria-hidden="true"
                              />
                              {user.email}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      ) : null}
      <div className="mt-10 divide-y divide-gray-200">
        <div className="space-y-1">
          <h3 className="text-lg font-medium leading-6 text-gray-900">Users</h3>
          <p className="max-w-2xl text-sm text-gray-500">
            Manage user access to your organization
          </p>
        </div>
        <div className="mt-6">
          <div className="overflow-hidden bg-white shadow sm:rounded-md">
            <ul className="divide-y divide-gray-200">
              {orgUsers.map((orgUser) => (
                <li key={orgUser.id}>
                  <div className="block hover:bg-gray-50">
                    <div className="px-4 py-4 sm:px-6">
                      <div className="flex items-center justify-between">
                        <NavLink
                          to={`/org/${org.slug}/users/${orgUser.user.id}`}
                        >
                          <p className="truncate text-sm font-medium text-indigo-600">
                            {`${orgUser.user.profile.firstName || ""} ${
                              orgUser.user.profile.lastName || ""
                            }`}
                          </p>
                        </NavLink>
                        <div className="z-1 ml-2 flex w-40 flex-shrink-0">
                          <Form method="patch" replace={true}>
                            <ListBox
                              id="accessRole"
                              name="accessRole"
                              items={accessRoles}
                              disabled={orgUser.user.id === user.id}
                              value={
                                accessRoles.find(
                                  (ar) => ar.id === orgUser.accessRole
                                ) || accessRoles[0]
                              }
                              onChange={handleAccessRoleChange.bind(
                                null,
                                orgUser.user.id
                              )}
                            />
                          </Form>

                          <div className="ml-4 flex justify-center align-middle">
                            {orgUser.user.id !== user.id ? (
                              <Form
                                method="delete"
                                onSubmit={onUserRemoved}
                                replace={true}
                                className="align-center flex flex-col justify-center"
                              >
                                <input
                                  type="hidden"
                                  name="_method"
                                  value="delete"
                                />
                                <input
                                  type="hidden"
                                  name="form"
                                  value="deleteOrgUser"
                                />
                                <button
                                  type="submit"
                                  name="deleteOrgUserId"
                                  value={orgUser.user.id}
                                >
                                  <TrashIcon className="h-5 w-5 flex-shrink-0 text-gray-400 hover:text-black" />
                                </button>
                              </Form>
                            ) : (
                              <div className="h-5 w-5 flex-shrink-0" />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="mt-2 sm:flex sm:justify-between">
                        <div className="sm:flex">
                          <p className="mr-6 flex items-center text-sm text-gray-500">
                            <EnvelopeIcon
                              className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                              aria-hidden="true"
                            />
                            {orgUser.user.email}
                          </p>
                          {orgUser?.title ? (
                            <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:mr-6">
                              <UsersIcon
                                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                aria-hidden="true"
                              />
                              {orgUser.title}
                            </p>
                          ) : null}
                          {orgUser?.providedId ? (
                            <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:mr-6">
                              <IdentificationIcon
                                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                aria-hidden="true"
                              />
                              {orgUser.providedId}
                            </p>
                          ) : null}
                          {orgUser.location && (
                            <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:mr-6">
                              <MapPinIcon
                                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                aria-hidden="true"
                              />
                              {orgUser.location?.name}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="mt-10 divide-y divide-gray-200">
        <div className="space-y-1">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Pending Invites
          </h3>
          {!orgInvites.length && (
            <p className="max-w-2xl text-sm text-gray-500">
              No pending invites.
            </p>
          )}
        </div>
        <div className="mt-6">
          <div className="overflow-hidden bg-white shadow sm:rounded-md">
            <ul className="divide-y divide-gray-200">
              {orgInvites.map((orgInvite) => (
                <li key={orgInvite.id}>
                  <div className="block hover:bg-gray-50">
                    <div className="px-4 py-4 sm:px-6">
                      <div className="flex items-center justify-between">
                        <p className="truncate text-sm font-medium text-indigo-600">
                          {orgInvite.email}
                          <span className="ml-2 inline-flex rounded-full bg-indigo-400 px-2 text-xs font-semibold leading-5 text-white">
                            Pending User Invite
                          </span>
                        </p>
                        <div className="ml-2 flex flex-shrink-0">
                          <Form
                            method="delete"
                            onSubmit={onUserRemoved}
                            replace={true}
                            className="align-center flex flex-col justify-center"
                          >
                            <input
                              type="hidden"
                              name="_method"
                              value="delete"
                            />
                            <input
                              type="hidden"
                              name="form"
                              value="deleteOrgUserInvite"
                            />
                            <button
                              type="submit"
                              name="deleteOrgInviteEmail"
                              value={orgInvite.email}
                            >
                              <TrashIcon className="h-5 w-5 flex-shrink-0 text-gray-400 hover:text-black" />
                            </button>
                          </Form>
                        </div>
                      </div>
                      <div className="mt-2 sm:flex sm:justify-between">
                        <div className="sm:flex">
                          {orgInvite.title ? (
                            <p className="flex items-center text-sm text-gray-500 sm:mr-6">
                              <UsersIcon
                                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                aria-hidden="true"
                              />
                              {orgInvite.title}
                            </p>
                          ) : null}
                          {orgInvite.providedId ? (
                            <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:mr-6">
                              <IdentificationIcon
                                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                aria-hidden="true"
                              />
                              {orgInvite.providedId}
                            </p>
                          ) : null}
                          {orgInvite.location && (
                            <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:mr-6">
                              <MapPinIcon
                                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                aria-hidden="true"
                              />
                              {orgInvite.location?.name}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <Notification
        isShowing={notify.isShowing}
        type={notify.type}
        hide={setNotify.bind(null, defaultNotify)}
        title={notify.title}
        message={notify.message}
        timeout={5000}
      />

      <Modal open={inviteModalOpen} setOpen={setInviteModalOpen}>
        <OrgInviteForm
          actionData={actionData}
          orgLocations={orgLocations}
          onSubmit={onSubmitInvite}
        />
      </Modal>
      <Modal
        open={orgAcceptModalOpen.open}
        setOpen={setOrgAcceptModalOpen.bind(null, { open: false })}
      >
        <OrgRequestAcceptForm
          userId={orgAcceptModalOpen.userId}
          email={orgAcceptModalOpen.email}
          actionData={actionData}
          orgLocations={orgLocations}
          onSubmit={onSubmitOtherOrgUserProfileUpdate}
        />
      </Modal>
    </div>
  );
}
