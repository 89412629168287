import { formatDistance } from "date-fns";
import { getDayStatusWithinDays } from "~/utils/utils";

export function formatExpirationDate(expirationDate: Date) {
  const relativeExpirationString = formatDistance(expirationDate, new Date(), {
    includeSeconds: false,
  });

  const status = getDayStatusWithinDays(30)(expirationDate);

  switch (status) {
    case "PAST":
      return (
        <span className="text-red-800">
          Expired {relativeExpirationString} ago
        </span>
      );
    case "UPCOMING":
      return (
        <span className="text-red-800">
          Expires in {relativeExpirationString}
        </span>
      );
    case "FUTURE":
      return (
        <span className="text-gray-800">
          Expires in {relativeExpirationString}
        </span>
      );
  }
}
