import { TrashIcon } from "@heroicons/react/24/outline";
import * as React from "react";

const MAX_FILE_SIZE = 10485760; // 10485760; // 10MB

type Props = {
  showSave?: boolean;
  forwardedRef?: React.Ref<HTMLInputElement>;
  error?: string;
};

function UploadImage({ showSave, forwardedRef, error }: Props) {
  const [uploadPreview, setUploadPreview] = React.useState<{
    url: string;
    type: string;
    error: string | null;
  } | null>(null);
  const localRef = React.useRef<HTMLInputElement>(null);
  const certUploadRef =
    (forwardedRef as React.RefObject<HTMLInputElement>) || localRef;

  return (
    <>
      <div className="flex flex-row">
        {uploadPreview ? (
          <div className="flex flex-col justify-center">
            <TrashIcon
              className="mr-1.5 h-8 w-8 flex-shrink-0 text-gray-400 hover:text-black"
              aria-hidden="true"
              onClick={() => {
                // clear out previous image
                setUploadPreview(null);
                if (certUploadRef && certUploadRef.current) {
                  certUploadRef.current.value = "";
                }
              }}
            />
          </div>
        ) : null}
        <div className="flex flex-col justify-center">
          <div className="mt-1 flex w-full justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
            {uploadPreview ? (
              <div className="relative flex flex-col justify-center">
                {uploadPreview.type === "application/pdf" ? (
                  <embed src={uploadPreview.url} width="400px" height="400px" />
                ) : (
                  <img
                    className="preview"
                    src={uploadPreview.url}
                    data-cy="certUploadPreview"
                    alt="Certification upload preview"
                  />
                )}
                {uploadPreview.error || error ? (
                  <div className="pt-1 text-red-700" id="title-error">
                    {uploadPreview.error || error}
                  </div>
                ) : null}
              </div>
            ) : (
              <div className="space-y-1 text-center">
                <svg
                  className="mx-auto h-12 w-12 text-gray-400"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 48 48"
                  aria-hidden="true"
                >
                  <path
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <div className="flex text-sm text-gray-600">
                  <label
                    htmlFor="certUpload"
                    className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                  >
                    <span>Upload a file</span>
                  </label>
                  <p className="pl-1">or drag and drop</p>
                </div>
                <p className="text-xs text-gray-500">
                  PNG, JPG, JPEG, PDF up to 10MB
                </p>
              </div>
            )}
          </div>
          {showSave && uploadPreview && (
            <button
              type="submit"
              className="mt-2 inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:border-slate-200 disabled:bg-slate-50 disabled:text-slate-500 disabled:shadow-none sm:w-auto"
              disabled={!!uploadPreview?.error || !!error?.length}
            >
              Save
            </button>
          )}
        </div>
        <input
          ref={certUploadRef}
          id="certUpload"
          data-cy="certUpload"
          name="certUpload"
          type="file"
          accept="image/jpeg,image/jpg,image/png,application/pdf,image/x-eps"
          className="sr-only"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const file = event.target.files ? event.target.files[0] : null;

            if (file && file.size > MAX_FILE_SIZE) {
              setUploadPreview({
                url: URL.createObjectURL(file),
                type: file.type,
                error: "File too large. Please upload a file under 10mb",
              });
              return;
            }

            setUploadPreview(
              file
                ? {
                    url: URL.createObjectURL(file),
                    type: file.type,
                    error: null,
                  }
                : null
            );
          }}
          disabled={!!uploadPreview?.error?.length}
          required
        />
      </div>
    </>
  );
}

const WrappedComponent = React.forwardRef(function forwardRefToComponent(
  props: Props,
  ref?: React.Ref<HTMLInputElement>
) {
  return <UploadImage {...props} forwardedRef={ref} />;
});

export default WrappedComponent;
