import { BuildingOfficeIcon } from "@heroicons/react/24/outline";
import type {
  Org,
  OrgLocation,
  OrgUser,
  OrgUserRequest,
  User,
  UserProfile,
} from "@prisma/client";
import { Form } from "@remix-run/react";
import { format } from "date-fns";
import * as React from "react";
import { ListBox, Notification, UpdateFormItem } from "~/components";
import { NotificationType } from "~/components/ui/notification";
import { dateWithTz } from "~/utils/utils";

const defaultState = {
  email: false,
  name: false,
  photo: false,
  phone: false,
  dateOfBirth: false,
  title: false,
  providedId: false,
  location: false,
};

type Props = {
  actionData: any;
  user: Pick<User, "id"> & Pick<User, "email"> & { profile: UserProfile };
  userOrg: OrgUser & { org: Org; location: OrgLocation };
  orgLocations?: OrgLocation[];
  userOrgRequests?: Array<OrgUserRequest & { org: Org }>;
  editableFields: Partial<typeof defaultState>;
};

const defaultNotify = {
  isShowing: false,
  type: NotificationType.SUCCESS,
  title: "",
  message: undefined,
  timeout: undefined,
};

export default function SettingsProfile({
  actionData,
  user,
  userOrg,
  orgLocations,
  userOrgRequests,
  editableFields = defaultState,
}: Props) {
  const [isEditing, setIsEditing] = React.useState(defaultState);
  const [notify, setNotify] = React.useState<{
    isShowing: boolean;
    type: NotificationType;
    title: string;
    message?: string;
    timeout?: number;
  }>(defaultNotify);

  function onToggleEditing(field: string, value: boolean) {
    if (value) {
      setIsEditing((prev) => ({ ...defaultState, [field]: true }));
    } else {
      setIsEditing(defaultState);
    }
  }

  function onSubmit(title: string) {
    setIsEditing(defaultState);
    setNotify({
      isShowing: true,
      type: NotificationType.SUCCESS,
      title,
    });
  }

  const firstNameRef = React.useRef<HTMLInputElement>(null);
  const lastNameRef = React.useRef<HTMLInputElement>(null);
  const phoneRef = React.useRef<HTMLInputElement>(null);
  const dateOfBirthRef = React.useRef<HTMLInputElement>(null);

  const titleRef = React.useRef<HTMLInputElement>(null);
  const providedIdRef = React.useRef<HTMLInputElement>(null);
  const locationRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (actionData?.errors?.firstName) {
      firstNameRef.current?.focus();
    } else if (actionData?.errors?.lastName) {
      lastNameRef.current?.focus();
    } else if (actionData?.errors?.phone) {
      phoneRef.current?.focus();
    } else if (actionData?.errors?.dateOfBirth) {
      dateOfBirthRef.current?.focus();
    } else if (actionData?.errors?.title) {
      titleRef.current?.focus();
    } else if (actionData?.errors?.providedId) {
      providedIdRef.current?.focus();
    } else if (actionData?.errors?.locationRef) {
      locationRef.current?.focus();
    } else if (actionData?.success) {
      setTimeout(() => {
        setNotify(defaultNotify);
      }, 2000);
    }
  }, [actionData]);

  return (
    <div>
      <div className="mt-10 divide-y divide-gray-200">
        <div className="mb-4 space-y-1">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Profile
          </h3>
          <p className="max-w-2xl text-sm text-gray-500">
            This information will be displayed publicly so be careful what you
            share.
          </p>
        </div>

        <Form
          className="space-y-4"
          method="patch"
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 8,
            width: "100%",
          }}
          encType="multipart/form-data"
          onSubmit={onSubmit.bind(null, "Profile updated!")}
          replace={true}
        >
          <input type="hidden" name="_method" value="patch" />
          <input type="hidden" name="form" value="updateUserProfile" />

          <dl className="divide-y divide-gray-200">
            <UpdateFormItem
              type="VIEW"
              label="Email"
              name="email"
              value={user.email}
              isEditing={isEditing.email}
              setIsEditing={onToggleEditing.bind(null, "email")}
              input={null}
            />

            <UpdateFormItem
              type={editableFields["name"] ? "EDIT" : "VIEW"}
              label="Name"
              name="name"
              value={`${user.profile.firstName || ""} ${
                user.profile.lastName || ""
              }`}
              isEditing={isEditing.name}
              setIsEditing={onToggleEditing.bind(null, "name")}
              input={
                <div className="mr-4 flex justify-center">
                  <div className="flex flex-wrap">
                    <input
                      id="firstName"
                      data-cy="firstName"
                      ref={firstNameRef}
                      name="firstName"
                      placeholder="First name"
                      type="text"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      aria-invalid={
                        actionData?.errors?.firstName ? true : undefined
                      }
                      aria-errormessage={
                        actionData?.errors?.firstName
                          ? "title-error"
                          : undefined
                      }
                      defaultValue={user.profile.firstName || ""}
                    />

                    {actionData?.errors?.firstName && (
                      <div className="pt-1 text-red-700" id="title-error">
                        {actionData?.errors?.firstName}
                      </div>
                    )}
                  </div>
                  <div className="ml-2">
                    <input
                      id="lastName"
                      data-cy="lastName"
                      ref={lastNameRef}
                      name="lastName"
                      placeholder="Last name"
                      type="text"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      aria-invalid={
                        actionData?.errors?.lastName ? true : undefined
                      }
                      aria-errormessage={
                        actionData?.errors?.lastName ? "title-error" : undefined
                      }
                      defaultValue={user.profile.lastName || ""}
                    />

                    {actionData?.errors?.lastName && (
                      <div className="pt-1 text-red-700" id="title-error">
                        {actionData?.errors?.lastName}
                      </div>
                    )}
                  </div>
                </div>
              }
            />

            {/* <UpdateFormItem
              type="EDIT"
              label="Photo"
              name="photo"
              value={
                <img
                  className="h-8 w-8 rounded-full"
                  src={user.profile.imageUrl}
                  alt=""
                />
              }
              isEditing={isEditing.photo}
              setIsEditing={onToggleEditing.bind(null, "photo")}
              input={
                <div className="flex flex-row justify-center">
                  <div>Upload image</div>
                </div>
              }
              additionalActions={[
                <button
                  key="remove"
                  type="button"
                  className="rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none"
                >
                  Remove
                </button>,
              ]}
            /> */}

            <UpdateFormItem
              type={editableFields["phone"] ? "EDIT" : "VIEW"}
              label="Phone Number"
              name="phone"
              value={user.profile.phone || ""}
              isEditing={isEditing.phone}
              setIsEditing={onToggleEditing.bind(null, "phone")}
              input={
                <div className="flex flex-row justify-center">
                  <div>
                    <input
                      id="phone"
                      data-cy="phone"
                      ref={phoneRef}
                      name="phone"
                      type="text"
                      autoComplete="tel"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      aria-invalid={
                        actionData?.errors?.phone ? true : undefined
                      }
                      aria-errormessage={
                        actionData?.errors?.phone ? "title-error" : undefined
                      }
                      defaultValue={user.profile.phone || ""}
                    />

                    {actionData?.errors?.phone && (
                      <div className="pt-1 text-red-700" id="title-error">
                        {actionData?.errors?.phone}
                      </div>
                    )}
                  </div>
                </div>
              }
            />

            <UpdateFormItem
              type={editableFields["dateOfBirth"] ? "EDIT" : "VIEW"}
              label="Date of Birth"
              name="dateOfBirth"
              value={
                user.profile.dateOfBirth
                  ? format(
                      dateWithTz(user.profile.dateOfBirth.toString()),
                      "yyyy-MM-dd"
                    )
                  : ""
              }
              isEditing={isEditing.dateOfBirth}
              setIsEditing={onToggleEditing.bind(null, "dateOfBirth")}
              input={
                <div className="flex flex-row justify-center">
                  <div>
                    <input
                      id="dateOfBirth"
                      data-cy="dateOfBirth"
                      ref={dateOfBirthRef}
                      name="dateOfBirth"
                      type="date"
                      className="picker block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      autoComplete="bday"
                      aria-invalid={
                        actionData?.errors?.dateOfBirth ? true : undefined
                      }
                      aria-errormessage={
                        actionData?.errors?.dateOfBirth
                          ? "title-error"
                          : undefined
                      }
                      defaultValue={
                        user.profile.dateOfBirth
                          ? format(
                              dateWithTz(user.profile.dateOfBirth.toString()),
                              "yyyy-MM-dd"
                            )
                          : ""
                      }
                    />

                    {actionData?.errors?.dateOfBirth && (
                      <div className="pt-1 text-red-700" id="title-error">
                        {actionData.errors?.dateOfBirth}
                      </div>
                    )}
                  </div>
                </div>
              }
            />
          </dl>
        </Form>
      </div>

      {userOrg?.org ? (
        <div className="mt-10 divide-y divide-gray-200">
          <div className="mb-4 space-y-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {userOrg.org.name || ""}
            </h3>
            <p className="max-w-2xl text-sm text-gray-500">
              Admins can edit your organization information
            </p>
          </div>
          <Form
            className="space-y-4"
            method="patch"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 8,
              width: "100%",
            }}
            onSubmit={onSubmit.bind(null, "Org Member updated!")}
            replace={true}
          >
            <input type="hidden" name="_method" value="patch" />
            <input type="hidden" name="form" value="updateOrgUser" />

            <UpdateFormItem
              type={editableFields["title"] ? "EDIT" : "VIEW"}
              label="Job Title"
              name="title"
              value={userOrg.title || ""}
              isEditing={isEditing.title}
              setIsEditing={onToggleEditing.bind(null, "title")}
              input={
                <div className="flex flex-row justify-center">
                  <div>
                    <input
                      id="title"
                      data-cy="title"
                      ref={titleRef}
                      name="title"
                      type="text"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      aria-invalid={
                        actionData?.errors?.title ? true : undefined
                      }
                      aria-errormessage={
                        actionData?.errors?.title ? "title-error" : undefined
                      }
                      defaultValue={userOrg.title || ""}
                    />

                    {actionData?.errors?.title && (
                      <div className="pt-1 text-red-700" id="title-error">
                        {actionData?.errors?.title}
                      </div>
                    )}
                  </div>
                </div>
              }
            />

            <UpdateFormItem
              type={editableFields["providedId"] ? "EDIT" : "VIEW"}
              label="Employee ID"
              name="providedId"
              value={userOrg.providedId || ""}
              isEditing={isEditing.providedId}
              setIsEditing={onToggleEditing.bind(null, "providedId")}
              input={
                <div className="flex flex-row justify-center">
                  <div>
                    <input
                      id="providedId"
                      data-cy="providedId"
                      ref={providedIdRef}
                      name="providedId"
                      type="text"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      aria-invalid={
                        actionData?.errors?.providedId ? true : undefined
                      }
                      aria-errormessage={
                        actionData?.errors?.providedId
                          ? "title-error"
                          : undefined
                      }
                      defaultValue={userOrg.providedId || ""}
                    />

                    {actionData?.errors?.providedId && (
                      <div className="pt-1 text-red-700" id="title-error">
                        {actionData?.errors?.providedId}
                      </div>
                    )}
                  </div>
                </div>
              }
            />

            {orgLocations && orgLocations.length ? (
              <UpdateFormItem
                type={editableFields["location"] ? "EDIT" : "VIEW"}
                label="Location"
                name="location"
                value={
                  orgLocations.find((l) => l.id === userOrg.locationId)?.name
                }
                isEditing={isEditing.location}
                setIsEditing={onToggleEditing.bind(null, "location")}
                input={
                  <div className="flex w-40 flex-row justify-center">
                    <ListBox
                      id="location"
                      name="location"
                      items={orgLocations}
                    />
                  </div>
                }
              />
            ) : null}
          </Form>
        </div>
      ) : userOrgRequests?.length ? (
        <div className="mt-10 divide-y divide-gray-200">
          <div className="space-y-1">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Requests to Join
            </h3>
            <p className="max-w-2xl text-sm text-gray-500">
              Pending requests to join organizations. Awaiting approval from an
              admin.
            </p>
          </div>
          <div className="mt-6">
            <div className="overflow-hidden bg-white shadow sm:rounded-md">
              <ul className="divide-y divide-gray-200">
                {userOrgRequests.map(({ org }) => (
                  <li key={org.id}>
                    <div className="block hover:bg-gray-50">
                      <div className="px-4 py-4 sm:px-6">
                        <div className="flex items-center justify-between">
                          <p className="flex truncate text-sm font-medium text-indigo-600">
                            <BuildingOfficeIcon
                              className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                              aria-hidden="true"
                            />
                            {org.name || ""}
                            <span className="ml-2 inline-flex rounded-full bg-red-400 px-2 text-xs font-semibold leading-5 text-white">
                              Awaiting Approval
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      ) : null}
      <Notification
        isShowing={notify.isShowing}
        type={notify.type}
        hide={setNotify.bind(null, defaultNotify)}
        title={notify.title}
        message={notify.message}
        timeout={5000}
      />
    </div>
  );
}
