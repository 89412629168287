import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { classNames } from "~/utils/utils";

type Props = {
  pages: { id: string; name: string; href: string; current: boolean }[];
};

export default function BreadCrumb({ pages }: Props) {
  return (
    <nav className="flex py-4" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-4">
        {pages.map((page, index) => (
          <li key={page.name}>
            <div className="flex items-center">
              {index !== 0 ? (
                <ChevronRightIcon
                  className="h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
              ) : null}
              <a
                data-cy={page.id}
                href={page.href}
                className={classNames(
                  "text-sm font-medium text-gray-500 hover:text-gray-700",
                  index !== 0 ? "ml-4" : null
                )}
                aria-current={page.current ? "page" : undefined}
              >
                {page.name}
              </a>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
}
