import { Disclosure, Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, FunnelIcon } from "@heroicons/react/24/outline";
import { Form } from "@remix-run/react";
import { Fragment } from "react";
import { camelCaseToTitleCase } from "~/utils/utils";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

type Props = {
  children: React.ReactNode;
  filters: {
    [key: string]: { value: string; label: string; checked: boolean }[];
  };
  sortOptions: { name: string; href: string; current: boolean }[];
  setSortOptions: (name: string) => void;
  setFilter: (category: string, value: string, checked: boolean) => void;
  clearFilters: () => void;
  usersCount: number;
  filteredUsersCount: number;
};

export default function Filter({
  children,
  filters,
  sortOptions,
  setSortOptions,
  setFilter,
  clearFilters,
  usersCount,
  filteredUsersCount,
}: Props) {
  const activeFilters = Object.keys(filters).filter((key) => {
    return filters[key].some((filter) => filter.checked);
  });

  function setFilterOnChangeEvent(event: React.ChangeEvent<HTMLInputElement>) {
    setFilter(event.target.name, event.target.value, event.target.checked);
  }

  return (
    <Form name="filter" method="get" className="bg-white">
      {/* Filters */}
      <Disclosure
        as="section"
        aria-labelledby="filter-heading"
        className="grid items-center border-t border-b border-gray-200"
      >
        <h2 id="filter-heading" className="sr-only">
          Filters
        </h2>

        <div className="relative col-start-1 row-start-1 py-4">
          <div className="mx-auto flex max-w-7xl space-x-6 divide-x divide-gray-200 px-4 text-sm sm:px-6 lg:px-8">
            <div>
              <Disclosure.Button className="text-gray-70 group flex flex-shrink items-center font-medium">
                <FunnelIcon
                  className="mr-2 h-5 w-5 flex-none text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
                <span className="hidden md:block">
                  {activeFilters.length} Filter
                  {activeFilters.length !== 1 ? <span>s</span> : null}
                </span>
              </Disclosure.Button>
            </div>
            {activeFilters.length ? (
              <div className="pl-6">
                <button
                  type="button"
                  className="text-gray-500"
                  onClick={clearFilters}
                >
                  Clear<span className="ml-1 hidden md:inline-block">all</span>
                </button>
              </div>
            ) : null}
            <div className="pl-6 ">
              {activeFilters.length ? (
                <span className="text-gray-500">
                  {filteredUsersCount} of {usersCount}
                  <span className="ml-1 hidden md:inline-block">Users</span>
                </span>
              ) : (
                <span className="text-gray-500">{usersCount} Users</span>
              )}
            </div>
          </div>
        </div>

        <Disclosure.Panel className="border-t border-gray-200 py-10">
          <div className="mx-auto grid max-w-7xl grid-cols-1 gap-x-4 px-4 text-sm sm:px-6 md:gap-x-6 lg:px-8">
            <div className="grid auto-rows-min grid-cols-1 gap-y-10 md:grid-cols-4 md:gap-x-6">
              {Object.keys(filters).map((category) => {
                if (!filters[category]?.length) {
                  return null;
                }
                return (
                  <fieldset key={category}>
                    <legend className="block font-medium">
                      {camelCaseToTitleCase(category)}
                    </legend>
                    <div className="space-y-6 pt-6 sm:space-y-4 sm:pt-4">
                      {filters[category].map((option, optionIdx) => (
                        <div
                          key={option.value}
                          className="flex items-center text-base sm:text-sm"
                        >
                          <input
                            id={`${category}-${optionIdx}`}
                            name={`${category}`}
                            defaultValue={option.value}
                            onChange={setFilterOnChangeEvent}
                            type="checkbox"
                            className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            checked={option.checked}
                          />
                          <label
                            htmlFor={`price-${optionIdx}`}
                            className="ml-3 min-w-0 flex-1 text-gray-600"
                          >
                            {option.label}
                          </label>
                        </div>
                      ))}
                    </div>
                  </fieldset>
                );
              })}
            </div>
          </div>
        </Disclosure.Panel>
        <div className="col-start-1 row-start-1 py-4">
          <div className="mx-auto flex max-w-7xl justify-end px-4 sm:px-6 lg:px-8">
            <Menu as="div" className="relative inline-block">
              <div className="flex">
                <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                  Sort
                  <ChevronDownIcon
                    className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    {sortOptions.map((option) => (
                      <Menu.Item key={option.name}>
                        {({ active }) => (
                          <a
                            href={option.href}
                            onClick={setSortOptions.bind(null, option.name)}
                            className={classNames(
                              option.current
                                ? "font-medium text-gray-900"
                                : "text-gray-500",
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm"
                            )}
                          >
                            {camelCaseToTitleCase(option.name)}
                          </a>
                        )}
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </Disclosure>
      {children}
    </Form>
  );
}
