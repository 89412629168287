import { TrashIcon } from "@heroicons/react/24/outline";
import type { Cert, CertUpload } from "@prisma/client";
import { Form } from "@remix-run/react";
import { UploadImage } from "~/components";
import { getFileExtensionFromUrl } from "~/utils/utils";

export default function CertUploadForm({
  cert,
  isEditing,
}: {
  isEditing: boolean;
  cert: Cert & { certUploads: CertUpload[] };
}) {
  return (
    <>
      <ul data-cy="uploads" className="grid grid-cols-1 gap-4 sm:grid-cols-2">
        {cert.certUploads.map((upload: CertUpload) => {
          const extension = getFileExtensionFromUrl(upload.uploadUrl);
          return (
            <li key={upload.id}>
              <div className="flex flex-row">
                {isEditing ? (
                  <Form
                    method="delete"
                    replace={true}
                    className="align-center flex flex-col justify-center"
                  >
                    <button type="submit" name="deleteUpload" value={upload.id}>
                      <TrashIcon className="mr-1.5 h-8 w-8 flex-shrink-0 text-gray-400 hover:text-black" />
                    </button>
                  </Form>
                ) : null}
                <div className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                  <div className="relative flex items-center space-x-3 rounded-md border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400">
                    <a href={upload.uploadUrl}>
                      {extension === "pdf" ? (
                        <embed
                          src={upload.uploadUrl}
                          width="400px"
                          height="400px"
                        />
                      ) : (
                        <img
                          data-cy="certUpload"
                          src={upload.uploadUrl}
                          alt={`Certification upload for ${upload.id}`}
                        />
                      )}
                    </a>
                  </div>
                </div>
              </div>
            </li>
          );
        })}
        {isEditing && !cert.certUploads.length ? (
          <li className="m-2">
            <UploadImage showSave={true} />
          </li>
        ) : null}
      </ul>
    </>
  );
}
