import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { Fragment, useEffect, useState } from "react";

type Props = {
  name: string;
  id?: string;
  disabled?: boolean;
  defaultValue?: { id: string; name: string };
  value?: { id: string; name: string };
  items: { id: string; name: string }[];
  onChange?: (id: string) => void;
};

export default function ListBox({
  name,
  id,
  items,
  disabled,
  defaultValue,
  value,
  onChange,
}: Props) {
  const [selected, setSelected] = useState<
    { id: string; name: string } | undefined
  >(value || defaultValue);

  function handleChange(change: any) {
    setSelected(change);
    if (onChange) {
      onChange(change.id);
    }
  }

  useEffect(() => {
    setSelected(value);
  }, [value]);

  return (
    <div className="relative w-full">
      <Listbox
        name={name}
        value={selected}
        by="id"
        onChange={handleChange}
        disabled={disabled}
      >
        <div className="relative mt-1 rounded-md border border-gray-300 shadow-sm">
          <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2  pl-3 pr-10 text-left shadow-sm focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 disabled:opacity-60 sm:text-sm">
            {selected ? (
              <span className="block truncate">{selected.name}</span>
            ) : (
              <span className="block truncate text-gray-700">Select</span>
            )}
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              id={id || "ListBox--select"}
              className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            >
              {items.map((item) => (
                <Listbox.Option
                  key={item.id}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active ? "bg-amber-100 text-amber-900" : "text-gray-900"
                    }`
                  }
                  value={item}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? "font-medium" : "font-normal"
                        }`}
                      >
                        {item.name}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}
