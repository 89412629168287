import { Tab } from "@headlessui/react";
import { Fragment, useState } from "react";
import { classNames } from "~/utils/utils";

type Props = {
  tabs: { name: string; content: React.ReactNode; disabled?: boolean }[];
  defaultTabIndex?: number;
  onChange?: (tabIndex: number) => void;
};

export default function Tabs({ tabs, defaultTabIndex = 0, onChange }: Props) {
  const [selectedIndex, setSelectedIndex] = useState(defaultTabIndex);

  function onSetSelected(tabIndex: number) {
    setSelectedIndex(tabIndex !== undefined ? tabIndex : defaultTabIndex);
    if (onChange) {
      onChange(tabIndex);
    }
  }

  return (
    <Tab.Group selectedIndex={selectedIndex} onChange={onSetSelected}>
      <label htmlFor="selected-tab" className="sr-only">
        Select a tab
      </label>

      <div>
        <div className="border-b border-gray-200">
          <Tab.List className="-mb-px flex space-x-8">
            {tabs.map((tab) => (
              <Tab key={tab.name} as={Fragment}>
                {({ selected }) => (
                  /* Use the `selected` state to conditionally style the selected tab. */
                  <button
                    disabled={tab.disabled}
                    className={classNames(
                      selected
                        ? "border-purple-500 text-purple-600"
                        : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                      "whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium focus:outline-none"
                    )}
                  >
                    {tab.name}
                  </button>
                )}
              </Tab>
            ))}
          </Tab.List>
        </div>
      </div>
      <Tab.Panels>
        {tabs.map(({ name, content }) => (
          <Tab.Panel key={name}>{content}</Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  );
}
