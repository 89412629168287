import { useMatches } from "@remix-run/react";
import crypto from "crypto";
import { addDays, isPast, isWithinInterval } from "date-fns";
import { useMemo } from "react";
import type { User, UserProfile } from "~/models/user.server";

const DEFAULT_REDIRECT = "/";

/**
 * This should be used any time the redirect path is user-provided
 * (Like the query string on our login/signup pages). This avoids
 * open-redirect vulnerabilities.
 * @param {string} to The redirect destination
 * @param {string} defaultRedirect The redirect to use if the to is unsafe.
 */
export function safeRedirect(
  to: FormDataEntryValue | string | null | undefined,
  defaultRedirect: string = DEFAULT_REDIRECT
) {
  if (!to || typeof to !== "string") {
    return defaultRedirect;
  }

  if (!to.startsWith("/") || to.startsWith("//")) {
    return defaultRedirect;
  }

  return to;
}

/**
 * This base hook is used in other hooks to quickly search for specific data
 * across all loader data using useMatches.
 * @param {string} id The route id
 * @returns {JSON|undefined} The router data or undefined if not found
 */
export function useMatchesData(
  id: string
): Record<string, unknown> | undefined {
  const matchingRoutes = useMatches();
  const route = useMemo(
    () => matchingRoutes.find((route) => route.id === id),
    [matchingRoutes, id]
  );
  return route?.data;
}

function isUser(user: any): user is User {
  return user && typeof user === "object" && typeof user.email === "string";
}

export function useOptionalUser():
  | (User & { profile: UserProfile })
  | undefined {
  const data = useMatchesData("root");
  if (!data || !isUser(data.user)) {
    return undefined;
  }
  return data.user as User & { profile: UserProfile };
}

export function useUser(): User & { profile: UserProfile } {
  const maybeUser = useOptionalUser();
  if (!maybeUser) {
    throw new Error(
      "No user found in root loader, but user is required by useUser. If user is optional, try useOptionalUser instead."
    );
  }
  return maybeUser;
}

export function isEmail(email: unknown): email is string {
  return typeof email === "string" && email.length > 3 && email.includes("@");
}

export function isDate(dateStr: string) {
  return typeof dateStr === "string" && !isNaN(new Date(dateStr).getDate());
}

export function uuid() {
  return crypto.randomUUID();
}

export function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

export function dateWithTz(dateString: string): Date {
  const dt = new Date(dateString);
  return new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);
}

export function getDayStatusWithinDays(days: number) {
  return function getDateStatus(date: Date): "PAST" | "FUTURE" | "UPCOMING" {
    const now = new Date();
    if (isPast(date)) {
      return "PAST";
    } else if (
      isWithinInterval(date, {
        start: now,
        end: addDays(now, 30),
      })
    ) {
      return "UPCOMING";
    }
    return "FUTURE";
  };
}

export function camelCaseToTitleCase(text: string) {
  const result = text.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export function isInvalidDate(dateString: any): boolean {
  return !dateString || new Date(dateString).toString() === "Invalid Date";
}

export function getFileExtensionFromUrl(url: string) {
  // example filenames from aws:
  // https://cert-uploads-dev.s3.us-west-2.amazonaws.com/clblr9h600002v5ec0ravgnfd/certs/167271760488.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAVEO44QBUUA2JFM5U%2F20230103%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20230103T034716Z&X-Amz-Expires=3600&X-Amz-Signature=eb962c5fd0ddce5bb109c7cc262adbfc171f85d1ddbe23170e69221f6abb6e7c&X-Amz-SignedHeaders=host&x-id=GetObject
  // Get the last portion of the URL (after the last '/')
  const lastUrlPortion = url.substring(url.lastIndexOf("/") + 1);
  // Get the portion of the URL before the query parameters (if any)
  const [fileName] = lastUrlPortion.split("?");
  // Get the file extension (if any)
  return fileName.substring(fileName.lastIndexOf(".") + 1);
}
